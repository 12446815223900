import styled from "styled-components"

const Text = styled.p`
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 16px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    margin-bottom: 24px;
  }
`

export default Text
