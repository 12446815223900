import React from "react"

//Styled components imports
import Wrapper from "./Wrapper"
import Technologies from "./Technologies"
import Technology from "./Technology"
import Title from "../../shared-styled-components/Title"
import Text from "../../shared-styled-components/Text"

const About = () => {
  return (
    <Wrapper>
      <Title>O mně</Title>
      <Text>
        Jsem freelancer specializující se na vývoj moderních webových stránek,
        aplikací a všeho mezi tím. Jsem tu pro zákazníky, kteří očekávají
        flexibilitu, spolehlivost a profesionalitu.
      </Text>
      <Text>
        Jsem odborníkem na <strong>HTML</strong>, <strong>CSS</strong> a{" "}
        <strong>JavaScript</strong> a primárně pracuji s{" "}
        <strong>Reactem</strong>.
      </Text>
      <Text>
        Zde je několik technologií, s kterými jsem v nedávné době pracoval:
      </Text>
      <Technologies>
        <Technology>HTML</Technology>
        <Technology>CSS (SASS)</Technology>
        <Technology>JavaScript (ES6+)</Technology>
        <Technology>React</Technology>
        <Technology>Redux</Technology>
        <Technology>Zustand</Technology>
        <Technology>Styled Components</Technology>
        <Technology>TailwindCSS</Technology>
        <Technology>Next.js</Technology>
        <Technology>Gatsby.js</Technology>
        <Technology>Strapi</Technology>
        <Technology>GraphQL</Technology>
        <Technology>REST</Technology>
        <Technology>Storybook</Technology>
        <Technology>Framer Motion</Technology>
      </Technologies>
      <Text>
        Navrhuji i uživatelská rozhraní. K vytvoření grafických návrhů používám
        Figmu, ve které zpravidla dodávám jak wireframy, tak finální grafický
        návrh včetně prototypů.
      </Text>
    </Wrapper>
  )
}

export default About
