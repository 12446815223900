import styled from "styled-components"

const Technologies = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 16px;
  padding: 0px 2px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 24px;
  }
`

export default Technologies
