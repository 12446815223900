import styled from "styled-components"

const Technology = styled.li`
  padding: 4px;

  &::before {
    content: "◆ ";
    color: ${props => props.theme.colors.main};
  }
`

export default Technology
