import styled from "styled-components"

const Title = styled.h1`
  font-weight: bold;
  font-size: 52px;
  text-align: center;
  margin: 40px 0px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    text-align: left;
    font-size: 72px;
    margin: 60px 0px;
  }
`

export default Title
