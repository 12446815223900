import React from "react"

//React components imports
import Layout from "../components/Layout"
import About from "../components/About"

const AboutPage = () => {
  return (
    <Layout location="about">
      <About />
    </Layout>
  )
}

export default AboutPage
